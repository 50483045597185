:global(.ant-list-item).listItemLoading {
  border: none;
  margin-right: 56px;
  width: 50px;
  padding: 8px 12px;
}

:global(.ant-list-item).listItemError {
	border: none;
  margin-right: 56px;
  padding: 8px 12px;
}
.consentContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consentForm {
  max-width: 400px;
  min-width: 300px;
}

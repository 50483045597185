:global(.ant-list-item).chatUserItem {
  justify-content: end;
  border: none;
  margin-left: 56px;
}

:global(.ant-card).chatUserItemCard {
  background-color: var(--brand-re);
  border: none;
  color: white;
  word-break: break-word;
}

:global(.ant-list-item).chatSystemItem {
  border: none;
  margin-right: 56px;
}

:global(.ant-card).chatSystemItemCard {
  background-color: var(--brand-la);
  border: none;
  color: black;
  word-break: break-word;
}
.typeIndicator {
  padding: '8px';
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
}

.dotFlashing {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	animation: dot-flashing 1s infinite linear alternate;
	animation-delay: 0.5s;
}

.dotFlashing::before,
.dotFlashing::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
}
.dotFlashing::before {
	left: -15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: var(--brand-re);
	color: var(--brand-fa);
	animation: dot-flashing 1s infinite alternate;
	animation-delay: 0s;
}
.dotFlashing::after {
	left: 15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: var(--brand-re);
	color: var(--brand-fa);
	animation: dot-flashing 1s infinite alternate;
	animation-delay: 1s;
}

@keyframes dot-flashing {
	0% {
		background-color: #9880ff;
	}
	50%,
	100% {
		background-color: rgba(152, 128, 255, 0.2);
	}
}
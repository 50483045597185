:root {
	--brand: #31336e;
	--brand-do: #3b3bc4;
	--brand-re: #5357ea;
	--brand-mi: #7e86f7;
	--brand-fa: #abb3ff;
	--brand-so: #d4d9ff;
	--brand-la: #f0f2ff;
	--brand-si: #f7f8ff;

	--black: #2c2c3a;

	--grey-si: #fbfbfb;
	--grey-fa: #dbdbdd;
	--grey-la: #f5f5f6;
	--grey-re: #81818f;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: white;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.ant-menu-item-selected {
	background-color: var(--brand-so) !important;
	color: var(--brand-re) !important;
}

.ant-menu-item:hover {
	background-color: var(--brand-la) !important;
	color: var(--brand-re) !important;
}

.ant-menu-item-selected:hover {
	background-color: var(--brand-so) !important;
	color: var(--brand-re) !important;
}

.ant-input:focus {
	border-color: var(--brand-re);
	box-shadow: 0 0 0 0.1rem rgba(83, 87, 234, 0.2);
}

.ant-input:hover {
	border-color: var(--brand-re);
	box-shadow: 0 0 0 0.1rem rgba(83, 87, 234, 0.2);
}

.ant-btn-loading {
	color: var(--brand);
}

.primary-button {
	background: var(--brand-re);
	width: calc(100% - 8px);
	margin: 8px 4px;
	border: none;
	color: white;
	border-radius: 6px;
	border: 1px solid var(--brand-re);
}

.primary-button:hover {
	background: var(--brand-mi) !important;
	color: white !important;
	border: 1px solid var(--brand-mi) !important	;
}

.ant-checkbox-checked > .ant-checkbox-inner {
	background-color: var(--brand-re) !important;
	/* border-bottom-color: var(--brand-re) !important; */
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
	border-color: var(--brand-re);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
	.ant-checkbox-checked:not(.ant-checkbox-disabled):after {
	border-color: var(--brand-re);
}

a {
	color: var(--brand-re);
}

a:hover {
	color: var(--brand-mi);
}

.open > span {
	display: none !important;
}

.ant-layout-sider-zero-width-trigger {
	background: var(--brand-mi) !important;
}

.ant-layout {
	background: white;
}

.no-color:hover {
	border-color: white !important;
}

.introPromtContainer {
  padding: 20px;
  border: 1px solid var(--grey-re);
  background: var(--grey-si);
  width: 244px;
  border-radius: 8px;
  color: var(--grey-re);
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.introPromtContainer > p {
  margin: 0;
}

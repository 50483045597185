.CTABannerHeader {
  text-align: center;
  background: var(--brand-re);
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 54px;
  height: unset;
}

.CTABannerContent {
  padding: 8px 0;
	line-height: 14px;
}

.CTABannerIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
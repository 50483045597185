.introPromptContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1280px;
}

.introPromptParagraph {
  color: var(--grey-re);
}

.introPromtBoxContainer {
  display: flex;
  text-align: center;
  gap: 46px;
  flex-wrap: wrap;
  justify-content: center;
}

.introPromtBoxHeading {
  color: var(--grey-re);
	margin-top: 0;
}